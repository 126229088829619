import { talentRequest, request } from "@/services/api";
import {
  mentorInviteUserLink,
  mentorInviteMentorLink,
  createOrganizationInviteLink,
} from "@/utils/invites";
import { cacheServise } from "@/services/chacheServise";
import findIndex from "lodash/findIndex";

export const mentor = {
  namespaced: true,
  state: () => ({
    organizations: [],
    userInvites: {},
    mentorInvites: {},
    organizationInvites: {},
    students: {},
    talentUsers: {},
    notifiedStudents: {},
    profiles: {},
    navigation: [
      {
        title: "Главная",
        name: "mentor-index",
        icon: "brand",
      },
      {
        title: "Расписание",
        name: "mentor-schedule",
        icon: "calendar",
      },
      {
        title: "Организации",
        name: "mentor-organizations",
        icon: "hands",
      },
    ],
    totalStudents: 0,
    // выбранные профили студентов
    selectedProfiles: {},
  }),
  mutations: {
    SET_STATE(state, payload) {
      state[payload.key] = payload.value;
    },
    SET_STUDENTS(state, payload) {
      state.students = { ...state.students, ...payload };
    },
    SET_NOTIFIED_STUDENT(state, participant) {
      state.notifiedStudents = {
        ...state.notifiedStudents,
        [participant]: Date.now(),
      };
    },
    SET_PROFILES(state, payload) {
      state.profiles = { ...state.profiles, ...payload };
    },
    DELETE_PROFILES(state, profile_id) {
      const profiles = { ...state.profiles };
      delete profiles[profile_id];
      state.profiles = profiles;
    },
    PATCH_ORG(state, { id, ...rest }) {
      if (!id) return;
      const idx = findIndex(state.organizations, (n) => n.id === id);
      if (idx !== -1) {
        state.organizations.splice(idx, 1, {
          ...state.organizations[idx],
          ...rest,
        });
      }
    },
  },
  getters: {
    orgList(state) {
      /**
       * для наставников нужно сортировать организации так, чтобы вверху списка
       * находилась Образовательная организация.
       */
      return [...state.organizations].sort((a, b) => {
        return b.educational_category > a.educational_category ? 1 : -1;
      });
    },
  },
  actions: {
    // Получение списка организаций
    async getMyOrganizations({ rootState, state, commit }, ignoreCache) {
      if (state.organizations.length && !ignoreCache) {
        return state.organizations;
      }
      const { data } = await talentRequest({
        url: `/api/users/${rootState.user.talentUser.id}/organizations/`,
        params: {
          limit: 50,
          offset: 0,
        },
      });
      commit("SET_STATE", {
        key: "organizations",
        value: data.results,
      });
      return state.organizations;
    },
    // Получение invite_code ментором для ученика
    async getUserInviteLink(
      { state, rootState, rootGetters, commit },
      { organization_id, profile_id }
    ) {
      const mentorId = rootState.user.talentUser.id;
      if (state.userInvites[organization_id]) {
        return mentorInviteUserLink({
          code: state.userInvites[organization_id],
          mentor: mentorId,
          org: organization_id,
          profile: profile_id,
        });
      }
      const { data } = await talentRequest({
        method: "POST",
        url: `/api/users/${mentorId}/organizations/${organization_id}/member/invite/code/`,
        data: {
          event: rootGetters.rootEvent,
        },
      });

      const link = mentorInviteUserLink({
        code: data.code,
        mentor: mentorId,
        org: organization_id,
        profile: profile_id,
      });

      commit("SET_STATE", {
        key: "userInvites",
        value: {
          ...state.userInvites,
          [organization_id]: data.code,
        },
      });
      return link;
    },

    async getMentorInviteLink({ state, rootState, commit }, organization_id) {
      if (state.mentorInvites[organization_id]) {
        return state.mentorInvites[organization_id];
      }
      const mentorId = rootState.user.talentUser.id;

      const { data } = await talentRequest({
        method: "GET",
        url: `/api/users/${mentorId}/organizations/${organization_id}/admins/invite/code/`,
      });

      const link = mentorInviteMentorLink({
        code: data.invite_code,
        org: organization_id,
      });

      commit("SET_STATE", {
        key: "mentorInvites",
        value: {
          ...state.mentorInvites,
          [organization_id]: link,
        },
      });
      return link;
    },

    async getOrganizationInviteLink(
      { state, rootState, commit },
      organization_id
    ) {
      if (state.organizationInvites[organization_id]) {
        return state.organizationInvites[organization_id];
      }
      const mentorId = rootState.user.talentUser.id;

      const { data } = await talentRequest({
        method: "GET",
        url: `/api/users/${mentorId}/organizations/${organization_id}/invite/code/`,
      });

      const link = createOrganizationInviteLink({
        code: data.organization_code,
        org: organization_id,
      });

      commit("SET_STATE", {
        key: "organizationInvites",
        value: {
          ...state.organizationInvites,
          [organization_id]: link,
        },
      });
      return link;
    },

    // Получение инфы о студентах
    async getStudents({ commit, rootState }, params = {}) {
      const { force, ...reqParams } = params;
      const payload = { ...reqParams };
      const url = "mentor/students";
      const season = rootState.season?.id;
      if (season) payload.season = season;
      const cached = cacheServise.getItem(url, payload);
      // если force:true то нужно игнорировать кеш
      if (!force && cached) {
        commit(
          "SET_STUDENTS",
          cached.items.reduce((acc, u) => {
            acc[u.user.id] = u;
            return acc;
          }, {})
        );
        commit("SET_STATE", { key: "totalStudents", value: cached.total });
        return cached;
      } else {
        const { data } = await request({
          method: "GET",
          url: "mentor/students",
          params: payload,
        });
        if (data.items?.length) {
          const result = data.items.reduce((acc, u) => {
            acc[u.user.id] = u;
            return acc;
          }, {});
          commit("SET_STUDENTS", result);
          commit("SET_STATE", { key: "totalStudents", value: data.total });
          cacheServise.setItem(url, payload, data, 1);
        } else {
          cacheServise.setItem(url, payload, data, 0.5);
        }
        return data;
      }
    },

    // Получение избранных профилей
    async getFavoritesProfiles({ commit, state, rootState }) {
      if (Object.keys(state.profiles).length) {
        return state.profiles;
      }
      const { data } = await request({
        method: "GET",
        url: "/favorites",
      });
      const profiles = data.favorite_profiles.reduce((acc, p) => {
        // Забираем только профили текущего сезона
        if (p.season_id === rootState.season?.id) {
          acc[p.id] = p;
        }
        return acc;
      }, {});
      commit("SET_STATE", {
        key: "profiles",
        value: profiles,
      });
    },
    // Добавление
    async selectProfile({ commit, rootState }, profile_id) {
      await request({
        url: `/favorites/${profile_id}`,
        method: "POST",
      });

      const profile = rootState.profile.profiles[profile_id];
      commit("SET_PROFILES", { [profile_id]: profile });
    },
    // Удаление
    async rejectProfile({ commit }, profile_id) {
      await request({
        url: `/favorites/${profile_id}`,
        method: "DELETE",
      });
      commit("DELETE_PROFILES", profile_id);
    },

    // Получение списка профилей студента
    async getStudentSelectedProfiles(ctx, params) {
      return await request({
        url: `/selected_profiles`,
        method: "GET",
        params,
      });
    },
  },
};
