<template>
  <div class="error-modal">
    <BaseModalContent
      :name="name"
      :dynamic="true"
      @close="$emit('close')">
      <div class="mb-m">
        <h2 class="text-bold text-size-h2">
          <span v-html="title"></span><span v-if="status"> ({{ status }})</span>
        </h2>
      </div>
      <div
        v-if="content"
        class="mb-s"
        v-html="content"></div>
      <div
        v-if="message"
        class="mb-l error-message">
        <p>{{ message }}</p>
        <div v-if="false">
          <span class="method">{{ url }}</span>
        </div>
      </div>

      <div class="mt-l">
        <BaseButton @click="$emit('close')">Закрыть</BaseButton>
      </div>
    </BaseModalContent>
  </div>
</template>

<script>
export default {
  name: "ErrorModal",
  props: {
    title: {
      type: String,
      default: "Произошла ошибка",
    },
    content: {
      type: String,
    },
    message: {
      type: String,
    },
    status: {
      type: [String, Number],
    },
    url: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      name: "error-modal",
    };
  },
};
</script>

<style lang="less" scoped>
.method {
  font-size: 14px;
  color: #515151;
}
</style>
