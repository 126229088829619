import dayjs from "dayjs";
import "dayjs/locale/ru";
const relativeTime = require("dayjs/plugin/relativeTime");
const customParseFormat = require("dayjs/plugin/customParseFormat");
const isBetween = require("dayjs/plugin/isBetween");
const minMax = require("dayjs/plugin/minMax");
const duration = require("dayjs/plugin/duration");
// const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
// const isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.locale("ru");
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(minMax);
dayjs.extend(isBetween);
dayjs.extend(duration);
// dayjs.extend(isSameOrBefore);
// dayjs.extend(isSameOrAfter);

export default dayjs;
