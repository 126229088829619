<template>
  <div class="container page-404">
    <h1 class="text-bold text-size-h1 mb-l">Тест</h1>
    <div class="wrap">
      <iframe
        src="https://talent.kruzhok.org/novel?foo=baz&utm_source=iframe&utm_medium=banner&utm_campaign=test_frame&utm_content=frame&utm_term=test"
        frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Test_Novel",
  computed: {
    ...mapGetters({
      user: "user/user",
      isMentor: "user/isMentor",
    }),
  },
  metaInfo() {
    return {
      title: "Страница не найдена",
    };
  },
};
</script>

<style lang="less" scoped>
.wrap {
  aspect-ratio: 9/16;
  max-width: 480px;
  min-width: 320px;
  margin-left: auto;
  margin-right: auto;
  background-color: #c0c0c0;

  iframe {
    width: 100%;
    height: 100%;
  }
}
.page-404 {
  text-align: center;
}

.title-404 {
  font-size: 10em;
  line-height: 1;

  &::v-deep .base-spinner {
    width: 0.73em !important;
    height: 0.73em !important;
  }

  &::v-deep .base-spinner__rotator {
    animation-duration: 20s;
  }
}
</style>
