<template>
  <div
    id="app"
    class="app-root">
    <a
      href="#main-content"
      class="skip-to-main-content-link">
      Перейти к основному содержанию
    </a>
    <transition
      v-if="loading"
      name="fade">
      <div class="loader">
        <BaseSpinner
          brand
          size="1em" />
      </div>
    </transition>
    <component
      :is="currentLayout"
      v-show="!loading">
      <transition name="fade">
        <router-view />
      </transition>
    </component>
    <notifications group="base" />
  </div>
</template>

<script>
import { LAYOUTS, DATE_SYNC_INTERVAL } from "@/constants";
export default {
  name: "AppRoot",
  components: {
    [LAYOUTS.default]: () =>
      import(/* webpackPrefetch: true */ "@/layouts/DefaultLayout.vue"),
    [LAYOUTS.reg]: () =>
      import(/* webpackPrefetch: true */ "@/layouts/RegistrationLayout.vue"),
    [LAYOUTS.mentor]: () =>
      import(/* webpackPrefetch: true */ "@/layouts/MentorLayout.vue"),
    [LAYOUTS.user]: () =>
      import(/* webpackPrefetch: true */ "@/layouts/UserLayout.vue"),
    [LAYOUTS.landing]: () =>
      import(/* webpackPrefetch: true */ "@/layouts/LandingLayout.vue"),
    [LAYOUTS.principal]: () =>
      import(/* webpackPrefetch: true */ "@/layouts/PrincipalLayout.vue"),
  },
  data() {
    return {
      syncTimer: null,
    };
  },
  metaInfo() {
    return {
      titleTemplate: `%s | ${process.env.VUE_APP_TITLE}`,
    };
  },
  computed: {
    /**
     * Активный лейаут страницы. Если не определен, то вернется дефолтный
     * @returns {string} layout
     */
    currentLayout() {
      const current = LAYOUTS[this.$store.state.ui.layout];
      if (current) return current;
      console.warn(
        `Layout with name ${this.$store.state.ui.layout} wasn't found. Fallback to default layout...`
      );
      return LAYOUTS.default;
    },
    loading() {
      return this.$store.state.ui.loading;
    },
  },
  errorCaptured(err) {
    if (process.env.NODE_ENV !== "production") {
      console.warn("LOOK WHAT YOU'VE DONE! ¯\\_( ͡❛ ⍨ ͡❛)_/¯");
      console.error("APP ERROR ===>>>", err);
    }
  },
  mounted() {
    this.syncTimer = setInterval(() => {
      this.$store.commit("SET_STATE", {
        key: "syncTimeStamp",
        value: Date.now(),
      });
    }, DATE_SYNC_INTERVAL);
  },
  beforeDestroy() {
    clearInterval(this.syncTimer);
  },
};
</script>
<style lang="less" src="@/less/common/common.less"></style>
<style lang="less" scoped>
.loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .fz(60);
  position: fixed;
  top: 0;
  left: 0;
  // background-color: @bg-light;
  z-index: 100;
}
</style>
