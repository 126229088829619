<template>
  <div class="req-consent">
    <div class="container">
      <RoleCard
        v-if="talentUser && talentUser.id"
        role="school">
        <template #label>Файл согласия</template>
        <template #body>
          <div v-if="requireConsentFile">
            <h1 class="text-size-h2 text-bold mb-m">
              Требуется загрузка файла согласия
            </h1>
            <div
              v-if="pending"
              class="consent-placeholder">
              <BaseSpinner
                class="mb-m"
                brand />
              <p class="color-meta">Подождите, мы проверяем файл...</p>
            </div>
            <div v-else>
              <div class="row">
                <div class="col-md-8 col-xs-12">
                  <p class="mb-m">
                    Загруженный вами файл согласия не&nbsp;прошел проверку,
                    пожалуйста, загрузите правильный файл, иначе
                    мы&nbsp;не&nbsp;сможем предоставить вам доступ
                    к&nbsp;Олимпиаде.
                  </p>
                  <div
                    v-if="talentUser.adult_confirm_comment"
                    class="mb-m">
                    <div class="text-bold mb-s">Комментарий модератора:</div>
                    <p>{{ talentUser.adult_confirm_comment }}</p>
                  </div>
                </div>
              </div>
              <div class="mt-l">
                <ul class="consent-steps">
                  <li class="consent-steps__item">
                    <BaseButton
                      target="_blank"
                      rel="noopener noreferrer"
                      tag="a"
                      theme="reg"
                      download="%D1%84%D0%B0%D0%B9%D0%BB%20%D1%81%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D1%8F.pdf"
                      :href="fileURL"
                      >Скачай шаблон</BaseButton
                    >
                  </li>
                  <li class="consent-steps__item">
                    Заполни документ с&nbsp;родителями или ответственными лицами
                  </li>

                  <li class="consent-steps__item">
                    <BaseButton
                      tag="label"
                      :disabled="filePending"
                      for="consentFileInput"
                      >Загрузи документ</BaseButton
                    >
                  </li>
                </ul>
                <div
                  v-if="fileError"
                  class="form-error mb-m">
                  {{ fileError }}
                </div>
                <div
                  v-if="consentCheckError"
                  class="form-error mb-m">
                  {{ consentCheckError }}
                </div>
                <p class="mt-s">
                  <a
                    href="https://talent.kruzhok.org/faq?category=personal_data"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="link"
                    >Зачем нам нужен этот файл?</a
                  >
                </p>
                <div class="file-wrapper">
                  <input
                    id="consentFileInput"
                    ref="fileInput"
                    type="file"
                    name="consentFile"
                    accept="image/jpeg,image/png,application/pdf"
                    @change="handleFileChange" />
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p class="mb-l">Файл согласия проверен или находится на проверке</p>
            <BaseButton @click="goNext">Перейти в личный кабинет</BaseButton>
          </div>
        </template>
      </RoleCard>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import RoleCard from "@/components/registration/RoleCard.vue";
import { talentRequest } from "@/services/api";
import { delay } from "@/utils";
import {
  UNDERAGE_CONSENT_FILE,
  UNDERAGE_CONSENT_FILE_JUNIOR,
  PARTICIPANT_ROLE,
  MENTOR_ROLE,
  ADULT_CONFIRM_MODERTION,
  ADULT_CONFIRM_APPROVED,
  ADULT_CONFIRM_REJECTED,
} from "@/constants";

const STATUS_POLING_TIMEOUT = 3000;
const MAX_REPEAT_COUNT = 30;
export default {
  name: "RequireConsent",
  components: {
    RoleCard,
  },
  metaInfo() {
    return {
      title: "Требуется загрузка файла согласия",
    };
  },
  async beforeRouteEnter(to, from, next) {
    try {
      await store.dispatch("user/getMe");
    } catch (error) {
      console.log("error", error);
      next("/error=not_authorized");
    }
    next();
  },
  data() {
    return {
      fileError: "",
      filePending: false,
      pending: false,
      repeatCount: 0,
      consentCheckError: "",
    };
  },
  computed: {
    ...mapGetters({
      age: "user/age",
      user: "user/user",
      requireConsentFile: "user/requireConsentFile",
    }),
    talentUser() {
      return this.$store.state.user.talentUser;
    },
    fileURL() {
      const { age } = this;

      if (age >= 14) return UNDERAGE_CONSENT_FILE;
      return UNDERAGE_CONSENT_FILE_JUNIOR;
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  mounted() {
    if (!this.talentUser || !this.talentUser.id) {
      console.log("Redirected from require-consent page");
      this.$router.push("/?error=user");
    }
  },
  methods: {
    async handleFileChange(event) {
      const { talentUser } = this;
      const file = event.target.files[0];
      if (!file || !talentUser) return;
      const fd = new FormData();
      fd.append("documents[0]", file);
      this.fileError = "";
      this.filePending = true;
      try {
        await talentRequest({
          url: `/api/users/${talentUser.id}/adult-confirms/`,
          method: "POST",
          data: fd,
        });
        this.polingStatus();
      } catch (error) {
        this.fileError = error.message;
      }
      this.filePending = false;
      this.$refs.fileInput.value = "";
    },
    stopPoling() {
      clearTimeout(this.timeout);
      this.pending = false;
    },
    async polingStatus() {
      this.pending = true;
      if (this.repeatCount >= MAX_REPEAT_COUNT) {
        this.stopPoling();
        this.$store.commit("user/PATCH_STATE", {
          key: "talentUser",
          value: {
            adult_confirmed: ADULT_CONFIRM_MODERTION,
          },
        });
        this.goNext();
        return;
      }
      try {
        this.repeatCount += 1;
        const { data } = await talentRequest({
          url: `/api/users/${this.talentUser.id}/adult-confirms/latest/`,
          method: "GET",
        });
        // если есть статус
        // если проверка удачна
        if (data.status === ADULT_CONFIRM_APPROVED) {
          await delay(STATUS_POLING_TIMEOUT);
          this.stopPoling();
          this.$store.commit("user/PATCH_STATE", {
            key: "talentUser",
            value: {
              adult_confirmed: data.status,
            },
          });
          this.goNext();
          return;
        } else if (data.status === ADULT_CONFIRM_REJECTED) {
          this.stopPoling();
          this.$store.commit("user/PATCH_STATE", {
            key: "talentUser",
            value: {
              adult_confirmed: "",
            },
          });
          return;
        } else if (
          !data.status &&
          (data.recognition_percent_filled > 0 || data.recognition_score > 0)
        ) {
          // если статуса нет но есть баллы распознавания
          // значит что файл переведен в статус - на модерации
          await delay(STATUS_POLING_TIMEOUT);
          this.stopPoling();
          this.$store.commit("user/PATCH_STATE", {
            key: "talentUser",
            value: {
              adult_confirmed: ADULT_CONFIRM_MODERTION,
            },
          });
          this.goNext();
          return;
        }
        this.timeout = setTimeout(this.polingStatus, STATUS_POLING_TIMEOUT);
      } catch (error) {
        this.consentCheckError = error.message;
        this.stopPoling();
      }
    },
    goNext() {
      const { next } = this.$route.query;
      if (next) {
        this.$router.push(next);
        return;
      }

      if (this.user?.role === PARTICIPANT_ROLE) {
        this.$router.push({
          name: "user-index",
        });
        return;
      }

      if (this.user?.role === MENTOR_ROLE) {
        this.$router.push({
          name: "mentor-index",
        });
        return;
      }

      this.$router.push("/?rc=1");
    },
  },
};
</script>

<style lang="less" scoped>
.consent-placeholder {
  min-height: 160px;
}
.file-wrapper {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  left: -9999px;
}
.consent-steps {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  .fz(14);
  counter-reset: list;

  & ::v-deep .base-btn {
    min-width: 180px;
  }

  &__item {
    padding-left: 3.2em;
    max-width: 270px;
    margin-right: 20px;
    counter-increment: list;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: 1em;
    margin-bottom: 1em;

    &::before {
      content: counter(list) ".";
      display: block;
      font-size: 3.2em;
      position: absolute;
      left: 0;
      font-weight: bold;
      top: 50%;
      line-height: 1;
      transform: translate(0, -50%);
      opacity: 0.5;
    }
  }

  @media screen and (max-width: @screen-md) {
    display: block;

    &__item {
      margin-bottom: 3em;
    }
  }
}
</style>
